import { tryFunc, mkChangeChecker, throttle, debounce, once } from "./utils";

const wx: undefined | {ready: (fn: () => any) => void} = (window as any).wx

export type PlayerStatus = 'playing' | 'pausing' | 'loading'

interface Handlers {
    status: ((status: PlayerStatus) => any)[]
    playing: (() => any)[]
    pausing: (() => any)[]
    loading: (() => any)[]

    ended: (() => any)[]
    timeUpdate: ((currentTime: number, duration: number) => any)[]
}

export class SimplePlayer {
    private readonly audio = document.createElement('audio')
    private status: PlayerStatus = 'pausing'
    private seekedTime: number = 0
    private readonly handlers: Handlers = {
        status: [], playing: [], pausing: [], loading: [], ended: [], timeUpdate: []
    }

    private intervals: { epoll: number, play: number, seek: number } = { epoll: 0, play: 0, seek: 0 }

    public static readonly _emptyAudio: string = 'data:audio/mpeg;base64,//FsgAPf/N4CAExhdmM1Ny4zOC4xMDAAQiAIwRg4//FsgAG//CEQBGCMHA=='
    private initReady: boolean = false
    private afterInitQueue: (() => any)[] = []

    constructor(autoInit: boolean = true) {
        if (autoInit) {
            this.init()
        }

        this.afterInit(() => {

            const checkTimeChange = mkChangeChecker(0)
            const checkStatusChange = mkChangeChecker()
            const checkTimeUpdateChange = mkChangeChecker()

            const emitTime = throttle((t: number, d: number) => {
                const key = `${t},${d}`
                if (checkTimeUpdateChange(key)) {
                    this.emit('timeUpdate', [t, d])
                }
            }, 300)

            const checkEmitStatusChange = mkChangeChecker()
            const emitStatus = debounce(() => {
                if (checkEmitStatusChange(this.status)) {
                    this.emit('status', [this.status])
                    this.emit(this.status, [])
                }
            }, 50)

            this.setInterval('epoll', () => {
                const curr = tryFunc(() => this.audio.currentTime) || 0
                const dur = tryFunc(() => this.audio.duration) || 0

                emitTime(this.intervals.seek > 0 ? this.seekedTime : Math.floor(curr), Math.floor(dur))
                if (checkTimeChange(curr)) {
                    this.status = "playing"
                } else {
                    if (this.audio.paused) {
                        this.status = 'pausing'
                    } else {
                        this.status = 'loading'
                    }
                }

                if (checkStatusChange(this.status)) {
                    emitStatus()
                }
            }, 30)

            this.audio.addEventListener('ended', () => this.emit('ended', []))
        })
    }

    public init = once(() => {
        const initReady = () => {
            if (!this.initReady) {
                this.audio.pause()
                this.audio.id = 'wk-global-player-audio'
                this.initReady = true
                document.body.appendChild(this.audio)
                this.afterInitQueue.forEach(fn => tryFunc(fn))
                document.removeEventListener('touchstart', initReady)
                document.removeEventListener('mousedown', initReady)
            }
        }

        document.addEventListener('touchstart', initReady)
        document.addEventListener('mousedown', initReady)

        if (wx) {
            wx.ready(initReady)
        }

        this.audio.src = SimplePlayer._emptyAudio
        try {
            this.audio.play()
                .then(initReady)
                .catch(() => void (0))
        } catch (err) {
            if (!this.audio.paused) {
                initReady()
            }
        }

    })

    private afterInit(fn: () => any) {
        if (this.initReady) {
            fn()
        } else {
            this.afterInitQueue.push(fn)
        }
    }
    
    private setInterval<K extends keyof SimplePlayer['intervals']>(key: K, fn: () => any, timeout: number) {
        fn()
        this.intervals[key] = window.setInterval(fn, timeout)
    }

    private clearInterval<K extends keyof SimplePlayer['intervals']>(key: K) {
        window.clearInterval(this.intervals[key])
        this.intervals[key] = 0
    }

    private emit<Event extends keyof Handlers>(event: Event, args: Parameters<Handlers[Event][0]>) {
        this.handlers[event].forEach((handler: Handlers[Event][0]) => (handler as any)(...args))
    }

    public load(src: string) {
        this.afterInit(() => {
            this.clearInterval('play')
            this.clearInterval('seek')

            this.audio.src = src
            tryFunc(() => this.audio.load())
        })
    }

    public play() {
        this.afterInit(() => {
            this.clearInterval('play')
            this.setInterval('play', () => {
                this.audio.paused
                    ? tryFunc(() => this.audio.play())
                    : this.clearInterval('play')
            }, 50)
        })
    }

    public seek(seekedTime: number) {
        this.afterInit(() => {
            this.clearInterval('seek')
            this.seekedTime = seekedTime

            this.setInterval('seek', () => { 
                const currentTime = tryFunc(() => this.audio.currentTime) || 0
                const duration = tryFunc(() => this.audio.duration) || 0

                if ((Math.abs(currentTime - seekedTime) < 0.3)
                    || (duration > 0 && seekedTime >= duration)) {
                    this.clearInterval('seek')
                } else {
                    tryFunc(() => this.audio.currentTime = seekedTime)
                }
            }, 50)

            setTimeout(() => this.emit('timeUpdate', [
                Math.floor(tryFunc(() => this.audio.currentTime) || 0),
                Math.floor(tryFunc(() => this.audio.duration) || 0),
            ]), 60)
        })
    }

    public playbackRate(rate?: number) {
        if (rate) {
            this.audio.playbackRate = rate
            this.audio.defaultPlaybackRate = rate
        }
        return this.audio.playbackRate
    }

    public pause() {
        this.afterInit(() => {
            this.clearInterval('play')
            tryFunc(() => this.audio.pause())
        })
    }

    public on<Event extends keyof Handlers>(event: Event, handler: Handlers[Event][0]) {
        this.handlers[event].push(handler as any)
    }

    public getStatus() {
        return {
            status: this.status,
            currentTime: tryFunc(() => this.audio.currentTime) || 0,
            duration: tryFunc(() => this.audio.duration) || 0,
        }
    }
}
