import { tryFunc } from "./utils";

export class StatusStorage<T extends {}>{
    private readonly status: T
    private readonly storageKey: string
    private readonly storage: Storage

    constructor(storageKey: string, defaultStatus: T, storage: Storage) {
        this.storage = storage
        this.storageKey = storageKey
        this.status = this.loadFromStorage() || defaultStatus
    }

    private loadFromStorage(): T | null {
        return tryFunc(() => JSON.parse(this.storage.getItem(this.storageKey) || 'null'))
    }

    private saveToStorage() {
        return this.storage.setItem(this.storageKey, JSON.stringify(this.status))
    }

    set<K extends keyof T>(key: K, value: T[K]) {
        if (this.status[key] !== value) {
            this.status[key] = value
            this.saveToStorage()
        }
    }

    get<K extends keyof T>(key: K): T[K] {
        return this.status[key]
    }

    getAll(): T {
        return JSON.parse(JSON.stringify(this.status))
    }
}