
export function tryFunc<T>(func: () => T): T | null {
    try {
        const p = func() as any
        if (p && p.catch) {
            p.catch(() => { })
        }
        return p
    } catch (err) {
        return null
    }
}

export function mkChangeChecker<T>(initial?: T): (value: T) => boolean {
    let prev = initial
    return (curr: T) => {
        const isChange = prev !== curr
        prev = curr
        return isChange
    }
}

export function debounce(func: () => any, wait: number): () => void {
    let timer = 0;
    return function () {
        if (timer !== null) {
            window.clearTimeout(timer)
        }
        timer = window.setTimeout(func, wait);
    }
}

export function throttle<Args extends any[]>(func: (...args: Args) => any, delay: number): (...args: Args) => void {
    let prev = 0
    let timer = 0

    function runFunc(...args: Args) {
        func(...args)
        prev = Date.now()
    }

    return function (...args: Args) {
        window.clearTimeout(timer)
        const delta = Date.now() - prev

        if (delta >= delay) {
            runFunc(...args)
        } else {
            timer = window.setTimeout(() => runFunc(...args), delay - delta)
        }
    }
}

export function once(fn: () => any) {
    let ready = false
    return function () {
        if (!ready) {
            fn()
            ready = false
        }
    }
}

export function clientWidth(): number {
    return window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
}

export function clientHeight(): number {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

export const os = (() => {
    const ua = navigator.userAgent
    const isWindowsPhone = /(?:Windows Phone)/.test(ua)
    const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
    const isAndroid = /(?:Android)/.test(ua)
    const isFireFox = /(?:Firefox)/.test(ua)
    const isChrome = /(?:Chrome|CriOS)/.test(ua)

    const isTablet
        = /(?:iPad|PlayBook)/.test(ua)
        || (isAndroid && !/(?:Mobile)/.test(ua))
        || (isFireFox && /(?:Tablet)/.test(ua))

    const isPhone = /(?:iPhone)/.test(ua) && !isTablet
    const isPc = !isPhone && !isAndroid && !isSymbian

    const isWeChat = /MicroMessenger/.test(navigator.userAgent) && !/WindowsWechat/.test(navigator.userAgent)
    const isIOS = navigator.userAgent.indexOf('iP') > -1
    const isPC = !/(iPhone|iPod|Android|ios)/i.test(navigator.userAgent)

    return {
        isTablet,
        isPhone,
        isAndroid,
        isPc,
        isChrome,
        isWeChat,
        isIOS,
        isPC
    }
})()

export function offsetRight() {
    return clientWidth() > 480 ? (clientWidth() - 480) / 2 : 0
}

export function astrict(right: number) {
    const offset = offsetRight()
    const width = 480
    const btnW = 54

    const rightLimit = offset
    const leftLimit = offset + width - btnW

    if (right < rightLimit) {
        return rightLimit
    } else if (right > leftLimit) {
        return leftLimit
    } else {
        return right
    }
}

export function isInLeft(right: number) {
    const width = clientWidth()
    return right > (Math.min(480, width) / 2)
}